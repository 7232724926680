<template>
  <div :data="level">
    <vs-table stripe max-items="10" pagination :data="student_connect_details">
      <template slot="header">
        <div style="display: flex;">
          <vs-button color="primary" icon="arrow_back" @click="$router.push({name:'StudentConnect'})"  size="small"></vs-button> &nbsp;
          <h3 style="margin: 0 0px 10px 10px">Student Connect Details - {{ level }} | {{ type_header }}</h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Identity </vs-th>
        <vs-th> Student Name </vs-th>
         <vs-th> Spoc Name </vs-th>
        <vs-th> Level </vs-th>
        <vs-th> Courses </vs-th>
        <vs-th v-if="type_of_connect !== 'not_connected'"> Last Call </vs-th>
        <vs-th v-if="type_of_connect !== 'not_connected'"> Call Duration (min) </vs-th>
        <vs-th> Action </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].identity">
            {{ data[indextr].identity }}
          </vs-td>
          <vs-td :data="data[indextr].person_name">
            {{ data[indextr].person_name }}
          </vs-td>
            <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>
          <vs-td :data="data[indextr].level">
            {{ data[indextr].level }}
          </vs-td>

          <vs-td :data="data[indextr].courses">
            {{ data[indextr].courses }}
          </vs-td>
          <vs-td :data="data[indextr].call_date" v-if="type_of_connect !== 'not_connected'">
            {{ convertDate(data[indextr].call_date) }}
          </vs-td>
          <vs-td :data="data[indextr].call_duration" v-if="type_of_connect !== 'not_connected'">
            {{ convertTime(data[indextr].call_duration) }}
          </vs-td>
          <vs-td :data="data[indextr].identity">
            <div @click="openPopupEmit(data[indextr])">
              <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";
export default {
  mounted() {
    console.log("studentConnect",this.$route.params.studentConnect)
    this.level = this.$route.params.studentConnect.level;
    this.type_of_connect = this.$route.params.studentConnect.type;
    this.type_header = this.$route.params.studentConnect.type_header;
    this.getStudentConnectDetails(this.level,this.type_of_connect);
  },
  data: () => ({
    student_connect_details: [],
    level: "",
    type_of_connect: '',
    type_header:""
  }),
  methods: {
    getStudentConnectDetails(level,type_of_connect) {
      this.$vs.loading();
      // let url = `${constants.MILES_DASHBOARDS}getStudentConnectDetails?level=${encodeURIComponent(level)}&type=${type_of_connect}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
        ApiServices.getStudentConnectDetails(level,type_of_connect)
        .then((response) => {
          this.student_connect_details = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    convertDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },
    convertTime(time) {
      return (time / 60).toFixed(2);
    },
    openPopupEmit(payload) {
      console.log("payload", payload);
      this.openBigPopup(payload.mwb_id);
    }
  },
};
</script>